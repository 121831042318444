<template>
  <!-- <v-container fluid> -->
  <!-- <v-dialog fullscreen persistent v-model="dialogModel">

        </v-dialog> -->
  <v-card flat>
    <!-- <v-card-title primary-title>
                PRINT
                <v-spacer></v-spacer>
                <v-btn @click="closePrint()" text color="red">Close</v-btn>
            </v-card-title> -->
    <!-- <v-card-text>
                <center> -->
    <editor :init="tiny_init" v-model="editorContent" id="tinymce" />
    <!-- </center>
            </v-card-text>-->
  </v-card>

  <!-- </v-container> -->
</template>

<script>
import axios from "axios";
import Editor from "@tinymce/tinymce-vue";
import tinymce from "tinymce/tinymce"; // 2022-02-21

import "tinymce/tinymce";
import "tinymce/themes/silver";
import "tinymce/icons/default";
import "tinymce/skins/ui/oxide/skin.css";
import "tinymce/skins/content/dark/content.min.css";
import "tinymce/plugins/advlist";
import "tinymce/plugins/autolink";
import "tinymce/plugins/lists";
import "tinymce/plugins/link";
import "tinymce/plugins/image";
import "tinymce/plugins/charmap";
import "tinymce/plugins/searchreplace";
import "tinymce/plugins/visualblocks";
import "tinymce/plugins/code";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/print";
import "tinymce/plugins/preview";
import "tinymce/plugins/anchor";
import "tinymce/plugins/insertdatetime";
import "tinymce/plugins/media";
import "tinymce/plugins/help";
import "tinymce/plugins/table";
import "tinymce/plugins/importcss";
import "tinymce/plugins/directionality";
import "tinymce/plugins/visualchars";
import "tinymce/plugins/template";
import "tinymce/plugins/codesample";
import "tinymce/plugins/hr";
import "tinymce/plugins/pagebreak";
import "tinymce/plugins/nonbreaking";
import "tinymce/plugins/toc";
import "tinymce/plugins/imagetools";
import "tinymce/plugins/textpattern";
import "tinymce/plugins/noneditable";
import "tinymce/plugins/emoticons";
import "tinymce/plugins/autosave";

import contentCss from "!!raw-loader!tinymce/skins/content/default/content.min.css";
import contentUiCss from "!!raw-loader!tinymce/skins/ui/oxide/content.min.css";
export default {
  components: {
    editor: Editor,
  },
  data() {
    return {
      dialogModel: true,
      editorContent: "",
      tiny_init: {
        object_resizing: false,

        inline: true,

        readonly: 1,

        toolbar: false,

        menubar: false,

        visual: false,

        skin: false,

        content_css: false,

        height: '100%',

        // content_style: [contentUiCss].join('\n') +'body { font-family:Times New Roman,Arial,sans-serif}',

        plugins: ["print", "pagebreak"],

        content_style:
          [contentCss, contentUiCss].join("\n") +
          "body { font-family:Times New Roman,Arial,sans-serif}",
      },
      // tiny_init : {
      //     branding: false,
      //         // editor_css : "/tinyEditor.css",
      //     height: 700,
      //     selector: 'textarea#image-tools',
      //     menubar: false,
      //     noneditable_noneditable_class: 'mceNonEditable',

      //     plugins: [
      //         'code',
      //         'code noneditable',
      //         'print','pagebreak'

      //     ],
      //     toolbar: 'code | print',
      //     content_style: [contentCss, contentUiCss].join('\n'),
      //     skin: 'lightgray',

      //     // content_css: '/sample.css',
      //  },
      shiyoData: "",
    };
  },
  methods: {
    closePrint() {
      window.close();
    },
    getDataToPrint() {
      axios
        .get(
          `${this.api}shiyo/get_shiyo/${this.$route.params.id}?productName=${this.$route.query.productName}`
        )
        .then((res) => {
          // let a = res.data.htmlContent
          // let b = []
          // for(let i = 0; i < a.length; i++){
          //     b.push(a[i]+'<!-- pagebreak -->')
          // }
          // console.log(this.editorContent)
          // this.editorContent = `<div class="myclass mceNonEditable">`+ b.toString().replace('<!-- pagebreak -->,', '<!-- pagebreak -->')+`</div>`
          // this.editorContent

          console.log("line 141", res.data);
          let length = res.data.htmlContent.length - 1;
          this.shiyoData = res.data;
          document.title = `${this.shiyoData.shiyoNumber}-${this.shiyoData.productName}`;
          // res.data.htmlContent = res.data.htmlContent.replace(
          //   /http:\\\\hrdapps48:5010\\uploads\\image\\froala\\test\\/g,
          //   "https://s3.us-east-2.amazonaws.com/rulebook.files/Shiyo/"
          // );
          let serverPhoto = new RegExp(
            `http://hrdapps48:5010/uploads/image/froala/test/`,
            "g"
          );
          res.data.htmlContent.forEach((rec, i) => {
            // 2022-02-21
            rec = rec.replace(
              serverPhoto,
              "https://s3.us-east-2.amazonaws.com/rulebook.files/Shiyo/"
            );
            this.editorContent += rec;
            if (i < length) {
              this.editorContent += "<p><!-- pagebreak --></p>";
            }
          });

          setTimeout(() => {
            let length = document.getElementsByTagName("table").length;
            let table = document.getElementsByTagName("table");
            // console.log("title",document.get)
            console.log("line 708", table);
            for (let i = 0; i <= length - 1; i++) {
              let width = document.getElementsByTagName("table")[i].style.width;
              if (width.includes("%")) {
                console.log(i, table[i].getAttribute("style"));
                let pixelWidth = (parseFloat(width) / 100) * 1200;
                let oldAttribute = table[i].getAttribute("style").toString();
                let widthIndex = oldAttribute.indexOf("width");
                let firstConcat = oldAttribute.substring(0, widthIndex);
                let scndConcat = oldAttribute.substring(
                  widthIndex + 17,
                  oldAttribute.length
                );
                table[i].setAttribute(
                  "style",
                  `${firstConcat + scndConcat} width: ${pixelWidth - 15}px;`
                );
              }
            }
          }, 200);

          setTimeout(() => {
            tinymce.activeEditor.execCommand("mcePrint");
            window.onafterprint = setTimeout(() => {
              window.close();
            }, 700);
          }, 1000);
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
  },
  created() {
    this.getDataToPrint();
  },
};
</script>

<style>
.mce-content-body {
  margin: 0;
}
.tox-notification {
  display: none !important;
}

.fr-view {
  font-family: "MS PGothic", Osaka, Arial, sans-serif;
  font-size: 18px;
}

.fr-view table.noborder tbody td {
  border: none;
}

.fr-view table.blackborder tbody td {
  border-color: black;
}
.fr-view td.tableCellStyles1 {
  color: red;
}

.fr-view span.rotateVerticalLeftRight {
  writing-mode: vertical-lr;
}
.fr-view .class1 tbody tr:nth-child(2n) {
  background: #d3d6db;
}
.fr-view .class2 thead tr th,
.class2 tbody tr td {
  border-style: dashed;
}
</style>
